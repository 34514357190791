import React from 'react'
import PropTypes from 'prop-types'
import { Spinner } from '@blueprintjs/core'

const Loading = props => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: '1',
        width: '100%',
        height: '100vh',
        background: 'rgba(255,255,255,0.5',
        paddingTop: '50px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <p className="loading--text">
        {props.message ? props.message : 'Loading…'}
      </p>

      <Spinner />
    </div>
  )
}

Loading.propTypes = {
  message: PropTypes.string,
}

export default Loading
