import React, { Suspense, lazy } from 'react'
import PropTypes from 'prop-types'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { connect } from 'react-redux'
import { Spinner } from '@blueprintjs/core'
import { Helmet } from 'react-helmet'
import { IntlProvider } from 'react-intl'
import { CookiesProvider } from 'react-cookie'

import PrivateRoute from './components/PrivateRoute'

const NotFoundPage = lazy(() => import('./pages/NotFound/NotFound'))
const LoginPage = lazy(() => import('./pages/Login/Login'))
const SignUpPage = lazy(() => import('./pages/SignUp/SignUp'))
const DashboardPage = lazy(() => import('./pages/Dashboard/Dashboard'))
const AgentsPage = lazy(() => import('./pages/Agents'))
const BlogPostsPage = lazy(() => import('./pages/BlogPosts'))
const PagesPage = lazy(() => import('./pages/Pages'))
const ProductsPage = lazy(() => import('./pages/Products'))
const PresentationsPage = lazy(() => import('./pages/Presentations'))
const PresentationCategoriesPage = lazy(() =>
  import('./pages/PresentationCategories'),
)
const PresentationVersionsPage = lazy(() =>
  import('./pages/PresentationVersions'),
)
const WebsitesPage = lazy(() => import('./pages/Websites'))
const WorkshopsPage = lazy(() => import('./pages/Workshops'))
const WorkshopSetsPage = lazy(() => import('./pages/WorkshopSets'))

const App = ({ user }) => {
  return (
    <>
      <CookiesProvider>
        <IntlProvider locale={navigator.language}>
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Back Office</title>
            </Helmet>

            <Router>
              <Suspense
                fallback={
                  <div
                    style={{
                      minHeight: '100vh',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Spinner />
                  </div>
                }
              >
                <Switch>
                  <Route exact path="/">
                    <Redirect to={`${user ? '/dashboard' : '/login'}`} />
                  </Route>

                  <Route exact path="/login" component={LoginPage} />
                  <Route exact path="/sign-up" component={SignUpPage} />

                  <PrivateRoute path="/dashboard" component={DashboardPage} />
                  <PrivateRoute path="/agents" component={AgentsPage} />
                  <PrivateRoute path="/blog-posts" component={BlogPostsPage} />
                  <PrivateRoute path="/pages" component={PagesPage} />
                  <PrivateRoute path="/products" component={ProductsPage} />
                  <PrivateRoute
                    path="/presentations"
                    component={PresentationsPage}
                  />
                  <PrivateRoute
                    path="/presentation-categories"
                    component={PresentationCategoriesPage}
                  />
                  <PrivateRoute
                    path="/presentation-versions"
                    component={PresentationVersionsPage}
                  />
                  <PrivateRoute path="/websites" component={WebsitesPage} />
                  <PrivateRoute path="/workshops" component={WorkshopsPage} />
                  <PrivateRoute
                    path="/workshop-sets"
                    component={WorkshopSetsPage}
                  />

                  <Route path="*">
                    <NotFoundPage />
                  </Route>
                </Switch>
              </Suspense>
            </Router>
          </>
        </IntlProvider>
      </CookiesProvider>
    </>
  )
}

App.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string,
    email: PropTypes.string,
  }),
}

App.defaultProp = {
  user: null,
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
})

export default connect(mapStateToProps)(App)
