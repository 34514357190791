import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null,
}

// Log in
const loginStart = (state) => {
  return updateObject(state, { error: null, loading: true })
}

const loginSuccess = (state, action) => {
  return updateObject(state, {
    isAuthenticated: true,
    user: action.user,
    loading: false,
    error: null,
  })
}

const loginFail = (state, action) => {
  return updateObject(state, {
    isAuthenticated: false,
    user: null,
    loading: false,
    error: action.error,
  })
}

// Log out
const logoutStart = (state) => {
  return updateObject(state, {
    loading: true,
  })
}

const logoutSuccess = (state) => {
  return updateObject(state, {
    isAuthenticated: false,
    user: null,
    loading: false,
    error: null,
  })
}

const logoutFail = (state, action) => {
  return updateObject(state, {
    isAuthenticated: false,
    user: null,
    error: action.error.message,
    loading: false,
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.LOGIN_START:
    return loginStart(state, action)
  case actionTypes.LOGIN_SUCCESS:
    return loginSuccess(state, action)
  case actionTypes.LOGIN_FAIL:
    return loginFail(state, action)
  case actionTypes.LOGOUT_START:
    return logoutStart(state, action)
  case actionTypes.LOGOUT_SUCCESS:
    return logoutSuccess(state)
  case actionTypes.LOGOUT_FAIL:
    return logoutFail(state, action)
  default:
    return state
  }
}

export default reducer
