import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

const PrivateRoute = ({ component: Component, user, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      user !== null ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: rest.location } }} />
      )
    }
  />
)

PrivateRoute.propTypes = {
  component: PropTypes.any,
  user: PropTypes.shape({
    _id: PropTypes.string,
    email: PropTypes.string,
    admin: PropTypes.number,
  }),
}

PrivateRoute.defaultProp = {
  user: null,
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
})

export default connect(mapStateToProps)(PrivateRoute)
