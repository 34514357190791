import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'

export const changeLanguage = (lng) => {
  i18n.changeLanguage(lng)
}

const namespaces = [
  'common',
  'menu',
  'navbar',
  'notFound',
  'presentations',
  'workshops',
  'workshopSets',
]

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'en',
    fallbackLng: 'en',
    ns: namespaces,
    defaultNS: 'common',
    debug: false, // process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      // loadPath: '/public/locales/{{lng}}/{{ns}}.json',
    },
  })

export default i18n
